/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$navbar-height: 40px;
$page-header-height: 50px;
$wizard-height: calc(100vh - #{$navbar-height} - #{$page-header-height});
$wizard-header-height: 45px;
$wizard-footer-height: 56px;

.overlay {
  border: none;
  position: fixed;
  z-index: map_get($zindex, "wizard-overlay");
  top: calc(#{$navbar-height} + #{$page-header-height});
  left: 0;
  right: 0;
  height: $wizard-height;
  display: flex;
}

.wizard {
  display: flex;
  width: 100%;
  background: $white;
  height: $wizard-height;
  flex-direction: column;
}

.wizard-header {
  $border-bottom-size: 3px;

  max-height: $wizard-header-height - $border-bottom-size;
  height: $wizard-header-height - $border-bottom-size;
  border-bottom: $border-bottom-size solid $border-color;
  display: flex;
  align-items: center;
  position: fixed;
  background: $white;
  width: 100%;
}

.wizard-footer {
  position: relative;
  bottom: 0;
  background: $white;
  border-top: 1px solid $border-color;
  height: $wizard-footer-height;
  max-height: $wizard-footer-height;
  padding: 10px 15px;
  flex: 0;
}

.wizard-body {
  position: relative;
  top: $wizard-header-height;
  display: flex;
  padding: 10px 15px;
  background: $white;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: $navbar-height;
}

.step-header {
  margin-left: 15px;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 5px;
  font-weight: 600;

  &.selected {
    margin-bottom: -3px;
    color: $go-primary;
    border-bottom: 3px solid $go-primary;
  }

  &.clickable {
    cursor: pointer;
  }
}

.step-body {
  font-weight: normal;
  width: 100%;
}
