/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.pagination-container {
  background-color: $white;
  border: 1px solid $global-border-color;
  border-radius: $global-border-radius;
  display: inline-flex;
  box-sizing: border-box;
  margin: 0 0 $global-margin-bottom 0;
}

.pagination-link {
  padding: 5px 10px;
  font-size: 14px;
  display: block;

  &.disabled {
    color: $go-disabled;
    cursor: not-allowed;
  }

  &.current-page {
    font-weight: bold;
    background-color: $secondary-bg;
  }

  & + & {
    border-left: 1px solid $global-border-color;
  }
}
