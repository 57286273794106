/*
 * Copyright 2022 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.environment-variable-wrapper {
  display: flex;

  .name {
    display: flex;

    &::after {
      content: "=";
      margin-right: 5px;
      line-height: 35px;
    }
  }

  .actions {
    line-height: 35px;
  }
}

.info-tooltip-wrapper {
  position: static;
  display: inline-block;

  &:hover {
    .info-tooltip-content {
      display: block;
    }
  }
}

.info-icon {
  @include icon-before($type: $fa-var-info-circle, $color: $icon-color);

  &::before {
    font-size: 16px;
    padding: 2px 12px 2px 0;
  }
}

.info-tooltip-content {
  display: none;
  background: $white;
  line-height: 18px;
  font-size: 12px;
  position: absolute;
  margin: 3px 10px 0 -8px;
  font-weight: 400;
  border-radius: $global-border-radius;
  padding: 10px;
  box-shadow: 0 2px 4px $box-shadow-color;
  z-index: map_get($zindex, submenu);

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0;
    left: 17px;
    box-sizing: border-box;
    border: 6px solid $black;
    border-color: $white $white transparent transparent;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 1px -1px 1px 0 $box-shadow-color;
  }
}

.group-container {
  width: 625px;
}
